import React from 'react';
import { ContentModule } from '@finn-no/content-module';
import type { AppProps } from '@finn-no/content-module';
import './styles.css';

export default function App(props: AppProps) {
    return (
        <React.StrictMode>
            <ContentModule {...props} />
        </React.StrictMode>
    );
}
